import React from "react";
import { useLocation } from "react-router-dom";

const Header = ()=>{
    

    const location = useLocation();

    //destructuring pathname from location
    const { pathname,} = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return(
        <header className="header header-transparent">
            <div className="d-none d-md-block">
                <div className="topbar bg-secondary">
                <div className="container">
                    <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <span className="topbar__text color-white mr-30">Vous pouvez télécharger nos applications sur :</span>
                        <div className="d-flex">
                        <a href="#" className="btn btn__white btn__download mx-2">
                            <i className="fab fa-apple"></i>
                            <span>App Store</span>
                        </a>
                        <a href="#" className="btn btn__white btn__download mx-2">
                            <i className="fab fa-google-play"></i>
                            <span>Google Play</span>
                        </a>
                        </div>
                        <button type="button" className="topbar__close"><i className="fas fa-times"></i></button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg sticky-navbar">
                <div className="container">
                    <button type="button" className="action__btn action__btn-burgerMenu mr-30 d-none d-lg-block">
                        <i className="icon-nav"></i>
                    </button>
                    <a className="navbar-brand" href="/">
                        <img src="wlogo2.png" className="logo-light" alt="logo"></img>
                        <img src="wlogo2.png" className="logo-dark" alt="logo"></img>
                    </a>

                    <button className="navbar-toggler" type="button">
                        <span className="menu-lines"><span></span></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mainNavigation">
                        <ul className="navbar-nav mr-auto ml-auto">
                            <li className="nav__item">
                                <a href="/"  className={splitLocation[1] === "" ? "nav__item-link active" : "nav__item-link"} >L'agence</a>
                            </li>

                            <li className="nav__item ">
                                <a href="/about" className={splitLocation[1] === "about" ? "nav__item-link active" : "nav__item-link"}>A Propos</a>
                            </li>
                            
                            <li className="nav__item">
                                <a href="/expertises" className={splitLocation[1] === "expertises" ? "nav__item-link active" : "nav__item-link"}>Expertises</a>
                            </li>

                            <li className="nav__item">
                                <a href="/contact" className={splitLocation[1] === "contact" ? "nav__item-link active" : "nav__item-link"}>Contacts</a>
                            </li>
                        </ul>
                    </div>
                    <ul className="navbar-actions list-unstyled mb-0 d-flex align-items-center">
                        <li className="d-none d-xl-block">
                        <a href="request-quote.html" className="btn btn__secondary btn__primary-style2 btn_ action__btn-contact">Rechercher</a>
                        </li>
                        <li>
                        <button className="action__btn action__btn-search">
                            <i className="icon-search"></i>
                        </button>
                        </li>
                    </ul>
                    
                </div>
            </nav>
        </header>
    )
}

export default Header