import React from "react";
import { Image } from "react-bootstrap";
const AboutSection= ()=>{
  const styles = {
    width: '83%',
  }
    return(
        <>
            <section className="about-layout2 pb-130">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                        <div className="about__img">
                        <img src="assets/images/page-titles/img2.png" alt="about"/>
                        
                        </div>{/* /.about-img */}
                    </div>{/* /.col-xl-5 */}
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
                        <div className="heading-layout2 mb-30">
                        <h2 className="heading__subtitle"></h2>
                        <h3 className="heading__title mb-30">Nous mettons à votre dispositions des solutions logicielles qui répondent à vos besoins</h3>
                        </div>{/* /heading */}
                        <div className="about__Text">
                        <p>Ambassadeur de la notion de création de « valeur perçue positive », West African technologies est réputé pour la création de site web 
                          avant-gardiste et de stratégies digitales et webmarketing créatives et innovantes.</p>
                        <p>Fondée par plusieurs acteur dans le domaine de l'informatique, nous accompagnons 
                            depuis 2015 de nombreuses marques et toutes tailles d’entreprises provenant de différents secteurs d’activités : industrie, tech, sport, 
                            automobile, santé, restauration, mode, services… </p>
                            <p>Nous sommes passionnés par notre domaine d’activité et les projets dans lesquels nous sommes investis.
                            Les valeurs qui émanent de notre agence web sont intrinsèquement ce qui lie notre équipe et ce qui nous rapproche de nos clients.
                            </p>
                        </div>
                    </div>{/* /.col-xl-7 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>

            <div className="team-layout1 text-center pb-40">
              <div className="container">
              <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                    <div className="heading text-center">
                      <h3 className="heading__title">Notre Equipe</h3>
                    </div>
                  </div>
                </div>
                <div className="row">

                  
                  {/* Member #2 */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/ceo2.png" alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>{/* /.social-icons */}
                          </div>{/* /.member-content-inner */}
                        </div>{/* /.member-hover */}
                      </div>{/* /.member-img */}
                      <div className="member__info">
                        <h5 className="member__name">Audrey Hounkpatin</h5>
                        <p className="member__desc">Co-Founder & Graphic Designer</p>
                      </div>{/* /.member-info */}
                    </div>{/* /.member */}
                  </div>{/* /.col-lg-4 */}

                  {/* Member #1 */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/1.jpg" alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons  justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="member__info">
                        <h5 className="member__name">Igore Adjotin</h5>
                        <p className="member__desc">Co-Founder & Software Engineer</p>
                      </div>
                    </div>
                  </div>
                 
                  {/* Member #3 */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/ceo3.png" alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>{/* /.social-icons */}
                          </div>{/* /.member-content-inner */}
                        </div>{/* /.member-hover */}
                      </div>{/* /.member-img */}
                      <div className="member__info">
                        <h5 className="member__name">Christel Avanon</h5>
                        <p className="member__desc">Co-Founder & Graphic Designer </p>
                      </div>{/* /.member-info */}
                    </div>{/* /.member */}
                  </div>{/* /.col-lg-4 */}
                </div> {/* /.row */}

                <div className="row">

                <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/amadou.jpg" style={styles} alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>{/* /.social-icons */}
                          </div>{/* /.member-content-inner */}
                        </div>{/* /.member-hover */}
                      </div>{/* /.member-img */}
                      <div className="member__info">
                        <h5 className="member__name">Tidiane Amadou</h5>
                        <p className="member__desc">Software Engineer</p>
                      </div>{/* /.member-info */}
                    </div>{/* /.member */}
                  </div>{/* */} {/* /.col-lg-4 */}

                  {/* Member #1 */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/1.jpg" alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons  justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>{/* /.social-icons */}
                          </div>{/* /.member-content-inner */}
                        </div>{/* /.member-hover */}
                      </div>{/* /.member-img */}
                      <div className="member__info">
                        <h5 className="member__name">Bernice Otokpo</h5>
                        <p className="member__desc">Responsable Administrative</p>
                      </div>{/* /.member-info */}
                    </div>{/* /.member */}
                  </div>{/* /.col-lg-4 */}
                  {/* Member #2 */}
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="member">
                      <div className="member__img">
                        <img src="assets/images/team/2.jpg" alt="member img"/>
                        <div className="member__hover">
                          <div className="member__content-inner">
                            <ul className="social-icons justify-content-center list-unstyled mb-0">
                              <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                              <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                              <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>{/* /.social-icons */}
                          </div>{/* /.member-content-inner */}
                        </div>{/* /.member-hover */}
                      </div>{/* /.member-img */}
                      <div className="member__info">
                        <h5 className="member__name">Michael Brian</h5>
                        <p className="member__desc">Chef de Production</p>
                      </div>{/* /.member-info */}
                    </div>{/* /.member */}
                  </div>{/* /.col-lg-4 */}
                  {/* Member #3 */}
                  
                </div>
              </div>{/* /.container */}
            </div>

            {/* Partenaires*/}
            <section className="clients border-top pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                            <div className="heading text-center mb-50">
                                <h3 className="heading__title">Ils nous font confiance</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="slick-carousel"
                                data-slick='{"slidesToShow": 5, "arrows": false, "dots": false, "autoplay": true,"autoplaySpeed": 2000, "infinite": true, "responsive": [ {"breakpoint": 992, "settings": {"slidesToShow": 4}}, {"breakpoint": 767, "settings": {"slidesToShow": 3}}, {"breakpoint": 480, "settings": {"slidesToShow": 2}}]}'>
                                <div className="client">
                                    <Image src="assets/images/clients/JP-logo.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/JP-logo.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-AEMC.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-AEMC.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-Fitnat.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-Fitnat.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-HCE.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-HCE.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-simda.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-simda.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-Wollonet.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-Wollonet.jpg" alt="client"></Image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection 