import React from "react";
import Particles from "../Particles";
 const  Section = ()=>{
    return(
        <>
        

            <section className="slider">
                <div className="slick-carousel carousel-arrows-light carousel-dots-light m-slides-0"
                    data-slick='{"slidesToShow": 1, "arrows": true, "dots": true, "autoplay": true,"autoplaySpeed": 5000, "infinite": true,"fade":true}'>
                    <div className="slide-item align-v-h bg-overlay">
                    
                        <div className="bg-img"><img src="test2.png" alt="slide img"></img></div>
                        

                        
                        <div className="container" id='reduct-height'>
                            <div className="row align-items-center">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className="slide-item__content">
                                    <h6 className="slide-item__title">L’innovation et la créativité au service de vos besoins.</h6>
                                    <p className="slide-item__desc">Augmentez votre visibilité en ligne et atteignez de nouveaux clients grâce à notre agence.</p>
                                    
                                    <a href="/about" className="btn btn__secondary btn__hi">En Savoir plus</a>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                    <div className=""><img src="test1.png" alt="slide img" id="second-img"></img></div>
                                </div>
                            

                                {/*<div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                    <div className="video-btn-wrapper d-flex justify-content-center">
                                    <a className="video__btn video__btn-lg video__btn-white popup-video"
                                        href="https://www.youtube.com/watch?v=nrJtHemSPW4">
                                        <div className="video__player">
                                        <span className="video__player-animation"></span>
                                        <span className="video__player-animation video__player-animation-2"></span>
                                        <i className="fa fa-play"></i>
                                        </div>
                                    </a>
                                    </div>
                                </div> */ }
                            </div>
                        </div>
                    </div>
                    <div className="slide-item align-v-h bg-overlay">
                        <div className="bg-img"><img src="assets/images/sliders/header2.png" alt="slide img"></img></div>
                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
                                    <div className="slide-item__content">
                                    <h2 className="slide-item__title">Solutions logicielles <br></br> pour les entreprises de toutes tailles.</h2>
                                    <p className="slide-item__desc">Améliorez votre productivité et votre efficacité grâce à nos logiciels innovants.</p>
                                    <a href="/expertises" className="btn btn__secondary btn__primary-style2 btn__hi">Notre Expertise</a>
                                    </div>
                                </div>
                                {
                                    /*
                                    
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                        <div className="video-btn-wrapper d-flex justify-content-center">
                                        <a className="video__btn video__btn-lg video__btn-white popup-video"
                                            href="https://www.youtube.com/watch?v=nrJtHemSPW4">
                                            <div className="video__player">
                                            <span className="video__player-animation"></span>
                                            <span className="video__player-animation video__player-animation-2"></span>
                                            <i className="fa fa-play"></i>
                                            </div>
                                        </a>
                                        </div>
                                    </div>
                                    */
                                }
                            </div>
                                <Particles id="tsparticlexs" />
                            </div>
                        </div>
                    </div>
            </section>
        </>
        
    )
    }

    export default Section