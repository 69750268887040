import React from 'react';

const ContactMap =()=> {
  
  const position = [71.505, -0.09]
  return (
    <>
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15860.6681453866!2d2.391115!3d6.3724246!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe9ccf35afdba4565!2sWestaf-Tech!5e0!3m2!1sfr!2sbj!4v1670700449541!5m2!1sfr!2sbj" 
        frameBorder="0"
        style={{ width: '100%', height: '400px' }}></iframe>
    </>
    
  );
}

export default ContactMap
