import React from "react";
import Footer from "../../components/main/footer";
import Header from "../../components/main/header";
import MainBody from "./main_body";
import BurgerMenu from "../../components/main/buger_menu";
const MainHome = ()=>{
    return(
        <>
            <Header/>
            <MainBody/>
            <Footer/>
            <BurgerMenu/>
        </>
    )
}

export default MainHome