import React from "react";
import Image from 'react-bootstrap/Image'
import moment from 'moment';
const Footer = ()=>{
    return(
        <>
        {/*westaftech1@gmail.com*/}
            <footer className="footer bg-heading">
                <div className="bg-img">
                    <Image src="assets/images/backgrounds/2.png" alt="backgrounds">
                    </Image>
                </div>
                <div className="footer-contact pt-50 pb-50">
                    <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-2">
                        <div className="contact-info">
                            <h6 className="footer-widget__title">Nos contacts</h6>
                            <ul className="contact-list list-unstyled mb-0">
                            <li><a href="tel:0022998147512">+229 98 14 75 12</a></li>
                            <li><a href="mailto:contact@westaf-tech.com">contact@westaf-tech.com</a></li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                        <div className="contact-info">
                            <h6 className="footer-widget__title">Notre adresse</h6>
                            <ul className="contact-list list-unstyled mb-0">
                            <li>99CR+FHF, Littoral Cotonou Bénin</li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-2">
                        <div className="contact-info">
                            <h6 className="footer-widget__title">Heures d'ouverture</h6>
                            <ul className="contact-list list-unstyled mb-0">
                            <li>Lun - Ven: 8h - 17h</li>
                            <li>Sam - Dim: Fermé</li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-6">
                        <h6 className="footer-widget__title">Newsletter</h6>
                        <form className="footer-form d-flex mb-0">
                            <input type="text" className="form-control mr-20" placeholder="Votre Address Email "></input>
                            <button type="submit" className="btn btn__primary btn__primary-style2">S'inscrire</button>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-primary">
                    <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 footer-widget footer-widget-about">
                        <div className="footer-widget__content">
                            <Image src="wlogo1.png" alt="logo" className="mb-30"></Image>
                            <p className="color-gray mb-40">Nous offrons de meilleures solutions au meilleurs prix.</p>
                            
                            <ul className="social-icons list-unstyled mb-0">
                            <li><a href="https://web.facebook.com/westaftech/" target={"_blank"}><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-2 footer-widget footer-widget-nav">
                        <h6 className="footer-widget__title">L'agence</h6>
                        <div className="footer-widget__content">
                            <nav>
                            <ul className="list-unstyled">
                                <li><a href="/about">A Propos</a></li>
                                <li><a href="/about">Notre Equipe</a></li>
                                <li><a href="#">Nouvelles et médias</a></li>
                                <li><a href="/contact">Contacts</a></li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-2 footer-widget footer-widget-nav">
                        <h6 className="footer-widget__title">Notre Expertise</h6>
                        <div className="footer-widget__content">
                            <nav>
                            <ul className="list-unstyled">
                                <li><a href="#">Développement logiciels</a></li>
                                <li><a href="#">Infographie</a></li>
                                <li><a href="#">Cyber Securité</a></li>
                                <li><a href="#">Cloud Computing</a></li>
                                <li><a href="#">Conseil en Informatique</a></li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-2 footer-widget footer-widget-nav">
                        <h6 className="footer-widget__title">Navigation</h6>
                        <div className="footer-widget__content">
                            <nav>
                            <ul className="list-unstyled">
                                <li><a href="#">Conditions d'utilisation</a></li>
                                <li><a href="#">Aide et FAQ</a></li>
                                <li><a href="#">Plan du site</a></li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-2 footer-widget footer-widget-nav">
                        <h6 className="footer-widget__title">Nous Embauchons</h6>
                        <div className="footer-widget__content">
                            <p className="fz-14 color-white">Intéressé à joindre l'équipe Westaf Tech ?</p>
                            <a href="careers.html" className="btn btn__primary btn__primary-style2 btn__link">
                            <span>Carrières</span><i className="icon-arrow-right icon-outlined"></i>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-secondary">
                    <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-center">
                        <span className="fz-14">&copy; 2018-{moment().format('YYYY')} Westaf Tech, Tous droits réservés.</span>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
            <button id="scrollTopBtn"><i className="fas fa-long-arrow-alt-up"></i></button>
        </>
    )
}

export default Footer