import React from "react";
import Partenaire from "../../components/home/partenaire";
import Section from "../../components/home/section";
import SectionPresentation from "../../components/home/section_present";
const MainBody = ()=>{
    return(
        <>
            <Section/>
            <SectionPresentation/>
            <Partenaire/>
        </>
    )
}

export default MainBody