import React from "react";
import Image from 'react-bootstrap/Image';
const Partenaire = ()=>{
    return(
        <>


            {/* Témoignages*/}
            <section id="testimonials" className="testimonials testimonials-layout1 text-center pt-0">
                <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="heading">
                        <h3 className="heading__title mb-0">Témoignages de nos clients </h3>
                    </div>{ /* /.heading */}
                    </div>{ /* /.col-lg-6 */}
                </div>{ /* /.row */}
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="testimonials-wrapper">
                        <div className="slider-with-navs">
                        { /* Testimonial #1 */}
                        <div className="testimonial-item">
                            <p className="testimonial-item__desc">Leur équipe est dynamique et très à l'écoute des exigences des clients. 
                            Les solutions qu'ils proposent sont sur mesures. J'ai très apprécié.
                            </p>
                            <div className="testimonial-item__meta">
                            <h4 className="testimonial-item__meta__title">Armelle Sanya</h4>
                            {/* <p className="testimonial-item__meta__desc">7oroof Inc</p>*/}
                            </div>{ /* /.testimonial-meta */}
                        </div>{ /* /. testimonial-item */}
                        { /* Testimonial #2 */}
                        <div className="testimonial-item">
                            <p className="testimonial-item__desc">Je les ai contactés en 2019 pour la visibilité de mon 
                                    entreprise et ils m'ont proposé des solutions qui ont comblées mes attentes.
                            </p>
                            <div className="testimonial-item__meta">
                            <h4 className="testimonial-item__meta__title">Kévin Biaou</h4>
                            </div>{ /* /.testimonial-meta */}
                        </div>{ /* /. testimonial-item */}
                        { /* Testimonial #3 */}
                        <div className="testimonial-item">
                            <p className="testimonial-item__desc">À force de créer quotidiennement, la créativité devient une seconde nature. Une extension du créateur. Force à vous.
                            Merci pour votre travail.
                            </p>
                            <div className="testimonial-item__meta">
                            <h4 className="testimonial-item__meta__title">Kenneth Hounyovi</h4>
                            </div>
                        </div>
                        
                        </div>
                        <div className="slider-nav">
                        <div className="testimonial-item__thumb">
                            <Image src="assets/images/testimonials/thumbs/1.png" alt="author thumb"></Image>
                        </div>
                        <div className="testimonial-item__thumb">
                            <Image src="assets/images/testimonials/thumbs/2.png" alt="author thumb"></Image>
                        </div>
                        <div className="testimonial-item__thumb">
                            <Image src="assets/images/testimonials/thumbs/3.png" alt="author thumb"></Image>
                        </div>
                        <div className="testimonial-item__thumb">
                            <Image src="assets/images/testimonials/thumbs/2.png" alt="author thumb"></Image>
                        </div>
                        <div className="testimonial-item__thumb">
                            <Image src="assets/images/testimonials/thumbs/3.png" alt="author thumb"></Image>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>

            {/* Partenaires*/}
            <section className="clients border-top pt-50 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 offset-lg-3">
                            <div className="heading text-center mb-50">
                                <h3 className="heading__title">Ils nous font confiance</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="slick-carousel"
                                data-slick='{"slidesToShow": 5, "arrows": false, "dots": false, "autoplay": true,"autoplaySpeed": 2000, "infinite": true, "responsive": [ {"breakpoint": 992, "settings": {"slidesToShow": 4}}, {"breakpoint": 767, "settings": {"slidesToShow": 3}}, {"breakpoint": 480, "settings": {"slidesToShow": 2}}]}'>
                                <div className="client">
                                    <Image src="assets/images/clients/JP-logo.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/JP-logo.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-AEMC.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-AEMC.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-Fitnat.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-Fitnat.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-HCE.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-HCE.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-simda.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-simda.jpg" alt="client"></Image>
                                </div>
                                <div className="client">
                                    <Image src="assets/images/clients/logo-Wollonet.jpg" alt="client"></Image>
                                    <Image src="assets/images/clients/logo-Wollonet.jpg" alt="client"></Image>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partenaire