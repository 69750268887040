import React from "react";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
const BurgerMenu = ()=>{
    return(
        <>
            <div className="burger-menu">
                <div className="burger-menu__content">
                    <button type="button" className="burger-menu__close"><i className="fas fa-times"></i></button>
                    <div className="burger-menu__header">
                        <Image src="wlogo1.png" alt="logo" className="mb-30"></Image>
                        <p className="color-white">Nous travaillons en étroite collaboration avec nos clients pour comprendre leurs 
                            objectifs commerciaux et créer des solutions personnalisées pour atteindre leurs objectifs</p>
                    </div>
                    <div className="contact-blocks-wrapper">
                    <div className="contact-block contact-block-light">
                        <h5 className="contact-block__title">Notre Adresse</h5>
                        <ul className="contact-block__list list-unstyled">
                        <li>99CR+FHF, Littoral Cotonou Bénin.</li>
                        </ul>
                    </div>
                    <div className="contact-block contact-block-light">
                        <h5 className="contact-block__title">Nos Contacts</h5>
                        <ul className="contact-block__list list-unstyled">
                        <li><a href="mailto:contact@westaf-tech.com"></a>Email: contact@westaf-tech.com</li>
                        <li><a href="tel:0022998147512"></a>Téléphone: +229 98 14 75 12</li>
                        </ul>
                    </div>
                    <div className="contact-block contact-block-light">
                        <h5 className="contact-block__title">Heures d'ouverture</h5>
                        <ul className="contact-block__list list-unstyled">
                        <li>Lundi - Vendredi</li>
                        <li>8h à 17h</li>
                        </ul>
                    </div>
                    <ul className="social-icons list-unstyled mb-0 mt-20">
                        <li><a href="https://web.facebook.com/westaftech/" target={"_blank"}><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    </ul>
                    </div>
                </div>
            </div>
            
            <div className="search-popup">
                <button type="button" className="search-popup__close"><i className="fas fa-times"></i></button>
                <Form className="search-popup__form">
                    <Form.Control type="text" className="search-popup__form__input" placeholder="Rechercher"></Form.Control>
                    <button className="search-popup__btn"><i className="icon-search"></i></button>
                </Form>
            </div> 
            
            
        </>
    )
}

export default BurgerMenu