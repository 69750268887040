import React from "react";
import Header from "../../components/main/header";
import Footer from "../../components/main/footer";
import BurgerMenu from "../../components/main/buger_menu";
import SectionBaniere from "../../components/main/section";
import AboutSection from "../../components/about/section";
const About =()=>{
    return(
        <>
            <Header/>
            <SectionBaniere img="assets/images/page-titles/img3.png" titre="Notre Agence"/>
            <AboutSection/>
            <Footer/>
            <BurgerMenu/>
        </>
    )
}

export default About