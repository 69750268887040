import React from "react";
import Header from "../../components/main/header";
import Footer from "../../components/main/footer";
import BurgerMenu from "../../components/main/buger_menu";
import SectionContact from "../../components/contact/section";
import ContactMap from "../../components/contact/map";
const Contacts =()=>{
    return(
        <>
            <Header/>
            <ContactMap/>
            <SectionContact/>
            <Footer/>
            <BurgerMenu/>
        </>
    )
}

export default Contacts