import React from "react";
import Header from "../../components/main/header";
import Footer from "../../components/main/footer";
import BurgerMenu from "../../components/main/buger_menu";
import SectionBaniere from "../../components/main/section";
import SectionExpertise from "../../components/expertises/section";
const Expertise =()=>{
    return(
        <>
            <Header/>
            <SectionBaniere img="assets/images/page-titles/Expertise.jpg" titre="Expertises"/>
            <SectionExpertise/>
            <Footer/>
            <BurgerMenu/>
        </>
    )
}

export default Expertise