import React from 'react';
import MainHome from './pages/home/main'
import Contacts from './pages/contact/contact';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import About from './pages/about/about';
import Expertise from './pages/expertises/expert';
function App() {
  return (
    <Router>

        <Routes>
          <Route exact path='/' element={<MainHome />}/>
          <Route exact path='/about' element={<About />}/>
          <Route exact path='/expertises' element={<Expertise />}/>
          <Route exact path='/contact' element={<Contacts />}/>
          
        </Routes>

    </Router>
  );
}

export default App;
