import React , { useRef,useState } from "react";
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Navigate} from 'react-router-dom';

const SectionContact =()=>{
    const form = useRef();
    
    const handleSubmit = event => {
        event.preventDefault();
    
        emailjs.sendForm('service_sokmpq9', 'template_pgem5qs', form.current, 'vfKkarpgMxtNhZlck')
            .then((result) => {
                console.log(result.text);
                handleShow();
            }, (error) => {
                console.log(error.text);
            });
        };
    
        const [show, setShow] = useState(false);

        const handleClose = () => {
            setShow(false);
            form.current.reset();
        };
        const handleShow = () => setShow(true);
    
    return(
        <>
            <section className="contact-layout1">
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <form ref={form} className="contact-panel__form"  onSubmit={handleSubmit} >
                            <div className="row">
                                <div className="col-sm-12">
                                <h4 className="contact-panel__title">Prenez un rendez-vous</h4>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="contact-name">Nom <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Nom" id="contact-name" name="name"
                                    required/>
                                </div>
                                </div>{/* /.col-lg-6 */}
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="contact-email">Email <span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" placeholder="Email" id="email" name="email"
                                    required/>
                                </div>
                                </div>{/* /.col-lg-6 */}
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="contact-Phone">Téléphone <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Téléphone" id="contact-Phone" name="phone"
                                    required/>
                                </div>
                                </div>{/* /.col-lg-6 */}
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                <div className="form-group">
                                    <label htmlFor="contact-website">Site Web</label>
                                    <input type="text" className="form-control" placeholder="Site Web " id="website"
                                    name="website"/>
                                </div>
                                </div>{/* /.col-lg-6 */}
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="form-group mb-20">
                                    <label htmlFor="contact-message">Message <span className="text-danger">*</span></label>
                                    <textarea className="form-control" placeholder="Ecrivez votre message ici" id="message"
                                    name="message" required></textarea>
                                </div>
                                <div className="custom-control custom-checkbox d-flex align-items-center  mb-20">
                                    <input type="checkbox" className="custom-control-input" required id="terms" />
                                    <label className="custom-control-label" htmlFor="terms">J'accepte la confidentialité et les conditions.</label>
                                </div>
                                <button type="submit" className="btn btn__secondary btn__block ">Envoyer</button>
                                <div className="contact-result" id='sendok'></div>
                                </div>{/* /.col-lg-12 */}
                            </div>{/* /.row */}
                        </form>
                    </div>{/* /.col-lg-6 */}
                    <div className="col-sm-12 col-md-12 col-lg-5 offset-lg-1">
                        <div className="contact-panel__info bg-overlay bg-overlay-primary">
                        <div className="bg-img"><img src="assets/images/contact/1.jpg" alt="banner"/></div>
                        <div className="contact-block">
                            <h5 className="contact-block__title">Notre Adresse</h5>
                            <ul className="contact-block__list list-unstyled">
                            <li>99CR+FHF, Littoral Cotonou Bénin.</li>
                            </ul>
                        </div>{/* /.contact-panel__info__block */}
                        <div className="contact-block">
                            <h5 className="contact-block__title">Contact</h5>
                            <ul className="contact-block__list list-unstyled">
                            <li><a href="mailto:contact@westaf-tech.com"></a>Email: contact@westaf-tech.com</li>
                            <li><a href="tel:0022998147512"></a>Téléphone: +229 98 14 75 12</li>
                            </ul>
                        </div>{/* /.contact-panel__info__block */}
                        <div className="contact-block">
                            <h5 className="contact-block__title">Horaires d'ouvertures</h5>
                            <ul className="contact-block__list list-unstyled">
                            <li>Lundi - Vendredi</li>
                            <li>08h:00 - 17h:00 PM</li>
                            </ul>
                        </div>{/* /.contact-panel__info__block */}
                        <a href="/contact" className="btn btn__white btn__bordered btn__icon btn__xl">
                            <span>Demandez un devis</span>
                            <i className="icon-arrow-right"></i>
                        </a>
                        </div>
                    </div>{/* /.col-lg-5 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </section>{/* /.contact layout 1 */}


            {/* Modal */}

      <Modal show={show} onHide={handleClose} backdrop="static"
        keyboard={false} >
        <Modal.Header closeButton>
          <Modal.Title>Message envoyé avec succes</Modal.Title>
        </Modal.Header>
        <Modal.Body>L'équipe de Westaf Tech vous reviendra sous peu.</Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn__secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

        </>
    )
}

export default SectionContact