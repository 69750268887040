import React from "react";
import { Image } from "react-bootstrap";
const SectionExpertise = ()=>{
    return(
        <>
{/* Présentation de solutions*/}
<section className="services-layout3 pt-40 pb-100">
            <div className="container">
                <div className="row">
                    {/* service item #5 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-programming"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Développement web<br/> et mobile</h4>
                            <p className="service-item__desc">Nous sommes en mesure de comprendre votre besoin et de vous proposer 
                                la solution la plus adaptée en matière de webdesign et de technologie web et mobile. Nous sommes une équipe d'experts en développement web et mobile 
                                et nous mettons notre expertise à votre disposition pour vous aider à atteindre vos objectifs professionnels.</p>
                            
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/5.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #1 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-server"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Marketing digital</h4>
                            <p className="service-item__desc">Nous analysons la concurrence et définissons un plan stratégique redoutable en fonction de vos cibles et de vos besoins, 
                                afin d’atteindre vos objectifs finaux. Notre équipe expérimentée à profil différent assure la réussite ainsi que le suivi de vos projets. La disponibilité, 
                                la réactivité, l'écoute et la qualité résument l´esprit et l'engagement de Westaf Tech vis-à-vis des projets de ses clients.</p>
                            
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img">
                                <Image src="assets/images/services/1.jpg" alt="background"></Image>
                            </div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #2 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-presentation"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">L'infographie<br/> </h4>
                            <p className="service-item__desc">Nous croyons que les visuels sont un élément clé pour attirer l'attention des clients et pour transmettre des informations 
                                de manière claire et concise. C'est pourquoi nous mettons notre expertise et notre expérience à votre disposition pour vous aider à créer des infographies, 
                                des illustrations, des logos, et tout autre type de visuel qui reflète votre entreprise et qui répond à vos besoins.</p>
                            
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/2.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #3 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-hosting"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Cloud Computing<br/></h4>
                            <p className="service-item__desc">Le cloud computing vous offre de nombreux avantages, tels que la flexibilité, la scalabilité, et la réduction 
                                    des coûts de gestion des systèmes informatiques.Nous proposons une gamme complète de services, 
                                    allant de la mise en place d'infrastructures cloud à la gestion et la maintenance de systèmes cloud existants. 
                            </p>
                            
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/3.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #4 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-technician"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Conseil en informatique <br/> </h4>
                            <p className="service-item__desc">Essayer de résoudre tous vos problèmes informatiques en interne peut devenir coûteux et être une distraction majeure. 
                                Tirer parti de nos conseils informatiques afin de gagner plus de temps et maintenir votre entreprise en sécurité.</p>
                            
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/4.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    
                    {/* service item #6 */}
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="service-item service-item-custom bg-overlay bg-overlay-primary">
                        <div className="bg-img">
                            <img src="assets/images/services/6.jpg" alt="background"/>
                        </div>
                        <div>
                            <h4 className="service-item__title">Faites nous confiance et prenez un rendez-vous.</h4>
                            <p className="service-item__desc">West African technologies est présent dans plusieurs pays dans le monde</p>
                        </div>
                        <a href="/contact" className="btn btn__white btn__bordered btn__icon btn__xl">
                            <span>Demandez un devis</span>
                            <i className="icon-arrow-right"></i>
                        </a>
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
            </section>

                {/* Clients Satisfaits*/}
                <section id="counters" className="counters pt-80">
                <div className="container">
                    <div className="row">
                    
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="counter-item">
                        <h4 className="counter">154</h4>
                        <p className="counter-item__desc">Projets réalisés</p>
                        </div>
                    </div>
                    
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="counter-item">
                        <h4 className="counter">15</h4>
                        <p className="counter-item__desc">Employés</p>
                        </div>
                    </div>
                    
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="counter-item">
                        <h4 className="counter">102</h4>
                        <p className="counter-item__desc">Clients satisfaits</p>
                        </div>
                    </div>
                    
                    <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                        <div className="counter-item">
                        <h4 className="counter">7</h4>
                        <p className="counter-item__desc">Années d'expérience</p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionExpertise