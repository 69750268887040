import React from "react";
import Image from 'react-bootstrap/Image';

const SectionPresentation = ()=>{
    return(
        <>
            <section className="about-layout1">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="heading-layout2 mb-30">
                        <h2 className="heading__subtitle">West African technologies</h2>
                        <h3 className="heading__title_perso mb-30">Nous sommes un partenaire de confiance pour les entreprises qui 
                        souhaitent augmenter leur visibilité en ligne et atteindre un public plus large.</h3>
                        <p className="heading__desc mb-25">Westaf Tech est une agence spécialisée dans le développement de solutions logicielles innovantes,
                            le développement de stratégies de marketing en ligne, la création et la gestion de sites web et l'infographie. Nous mettons notre expertise et 
                            notre expérience à votre disposition pour vous aider à trouver les solutions logicielles qui répondent le mieux à vos besoins,
                            à augmenter votre visibilité en ligne, à générer des leads qualifiés et à convertir ces leads en clients.</p>
                        </div>
                        <a href="/about" className="btn btn__secondary btn__xl btn__icon mb-30">
                        <span>À Propos</span>
                        <i className="icon-arrow-right"></i>
                        </a>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="about__img">
                        <Image src="assets/images/about/img1.jpg" alt="about"></Image>
                        
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            {/* Présentation de solutions*/}
            <section className="services-layout3 pt-40 pb-100">
            <div className="container">
                <div className="row">
                    {/* service item #5 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-programming"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Développement web<br/> et mobile</h4>
                            <p className="service-item__desc">Nous sommes en mesure de comprendre votre besoin et de vous proposer 
                                la solution la plus adaptée en matière de webdesign et de technologie web et mobile. Nous sommes une équipe d'experts en développement web et mobile 
                                et nous mettons notre expertise à votre disposition pour vous aider à atteindre vos objectifs professionnels.</p>
                            <a href="" className="btn btn__secondary btn__link">
                            <span>Lire la suite</span>
                            <i className="icon-arrow-right"></i>
                            </a>
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/5.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #1 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-server"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Marketing digital</h4>
                            <p className="service-item__desc">Nous analysons la concurrence et définissons un plan stratégique redoutable en fonction de vos cibles et de vos besoins, 
                                afin d’atteindre vos objectifs finaux. Notre équipe expérimentée à profil différent assure la réussite ainsi que le suivi de vos projets. La disponibilité, 
                                la réactivité, l'écoute et la qualité résument l´esprit et l'engagement de Westaf Tech vis-à-vis des projets de ses clients.</p>
                            <a href="/expertises" className="btn btn__secondary btn__link">
                            <span>Lire la suite</span>
                            <i className="icon-arrow-right"></i>
                            </a>
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img">
                                <Image src="assets/images/services/1.jpg" alt="background"></Image>
                            </div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #2 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-presentation"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">L'infographie<br/> </h4>
                            <p className="service-item__desc">Nous croyons que les visuels sont un élément clé pour attirer l'attention des clients et pour transmettre des informations 
                                de manière claire et concise. C'est pourquoi nous mettons notre expertise et notre expérience à votre disposition pour vous aider à créer des infographies, 
                                des illustrations, des logos, et tout autre type de visuel qui reflète votre entreprise et qui répond à vos besoins.</p>
                            <a href="/expertises" className="btn btn__secondary btn__link">
                            <span>Lire la suite</span>
                            <i className="icon-arrow-right"></i>
                            </a>
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/2.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #3 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-hosting"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Cloud Computing<br/></h4>
                            <p className="service-item__desc">Le cloud computing vous offre de nombreux avantages, tels que la flexibilité, la scalabilité, et la réduction 
                                    des coûts de gestion des systèmes informatiques.Nous proposons une gamme complète de services, 
                                    allant de la mise en place d'infrastructures cloud à la gestion et la maintenance de systèmes cloud existants. 
                            </p>
                            <a href="/expertises" className="btn btn__secondary btn__link">
                            <span>Lire la suite</span>
                            <i className="icon-arrow-right"></i>
                            </a>
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/3.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    {/* service item #4 */}
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        <div className="service-item">
                        <div className="service-item__content">
                            <div className="service-item__icon">
                            <i className="icon-technician"></i>
                            </div>{/* /.service-item__icon */}
                            <h4 className="service-item__title">Conseil en informatique <br/> </h4>
                            <p className="service-item__desc">Essayer de résoudre tous vos problèmes informatiques en interne peut devenir coûteux et être une distraction majeure. 
                                Tirer parti de nos conseils informatiques afin de gagner plus de temps et maintenir votre entreprise en sécurité.</p>
                            <a href="/expertises" className="btn btn__secondary btn__link">
                            <span>Lire la suite</span>
                            <i className="icon-arrow-right"></i>
                            </a>
                        </div>{/* /.service-content */}
                        <div className="service-item__overlay bg-overlay bg-overlay-primary">
                            <div className="bg-img"><img src="assets/images/services/4.jpg" alt="background"/></div>
                        </div>{/* /.service-item__overlay */}
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                    
                    {/* service item #6 */}
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="service-item service-item-custom bg-overlay bg-overlay-primary">
                        <div className="bg-img">
                            <img src="assets/images/services/6.jpg" alt="background"/>
                        </div>
                        <div>
                            <h4 className="service-item__title">Faites nous confiance et prenez un rendez-vous.</h4>
                            <p className="service-item__desc">West African technologies est présent dans plusieurs pays dans le monde</p>
                        </div>
                        <a href="/expertises" className="btn btn__white btn__bordered btn__icon btn__xl">
                            <span>Demandez un devis</span>
                            <i className="icon-arrow-right"></i>
                        </a>
                        </div>{/* /.service-item */}
                    </div>{/* /.col-lg-4 */}
                </div>{/* /.row */}
            </div>{/* /.container */}
            </section>
        </>
    )
}

export default SectionPresentation