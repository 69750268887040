import React from "react";

const SectionBaniere= (props)=>{

    return(
        <>
        {/*"assets/images/page-titles/3.jpg"*/}
            <section className="page-title page-title-layout3 text-center bg-overlay bg-parallax">
                <div className="bg-img"><img src={props.img} alt="background"/></div>
                <div className="container">
                    <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <h1 className="pagetitle__heading mb-10">{props.titre}</h1>
                    </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SectionBaniere 